import axios from "axios"
import { FormDataConsumer, SelectInput } from "react-admin"
import React, { useState } from 'react'


export const DepartmentInput = ({ allowEmpty, label, helperText, source, ...props }) => {

    const [departments, setDepartments] = useState(null)
  
    const getBachataOperatorsGroup = async accessToken => {
      if (accessToken === '' || accessToken.length !== 64) {
        return []
      }
  
      axios.get(`${process.env.REACT_APP_ENTRYPOINT}/api/bachata/operators`, {
        headers: {
          'x-authorization': accessToken
        }
      }).then(resp => {
        setDepartments(resp.data.groups)
      })
    }
  
    return (
      <>
        <FormDataConsumer {...props}>
          {({ formData, ...rest }) => {
            if (formData.rest_api_secret_key && formData.rest_api_secret_key.length === 64) {
              if (!departments) {
                getBachataOperatorsGroup(formData.rest_api_secret_key)
              }
              if (!Array.isArray(departments)) {
                return 'Загрузка ...'
              }
              return (
                <SelectInput allowEmpty={allowEmpty} label={label} helperText={helperText} source={source} optionText="descr" choices={departments} />
              )
            }
            return null
          }}
        </FormDataConsumer>
      </>
    )
  }