import React from 'react'

import {
  Create,
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
  TextInput,
  required
} from 'react-admin'

export const CreateBrightBoxIntegration = ({ permissions, ...props }) => {

  return (
    <Create {...props} title="Создание интеграции с Bright">
      <SimpleForm redirect={'list'}>

        <TextInput source="rest_api_secret_key" label="ID токена REST API Бачата" helperText={'ID токена, который вы получли на странице  Бачаты "Интеграция: REST API" '} validate={required()} />

        {
          +permissions >= 100 && (
            <ReferenceInput label="Компания" source="company_id" reference="companies" allowEmpty >
              <AutocompleteInput helperText='Компания, к которой принадлежит пользователь  Bright Box. Может быть создана во вкладке "Компании"' emptyText="нет компании" optionText="name" />
            </ReferenceInput>
          )
        }

      </SimpleForm>
    </Create>
  )
}


