import React from 'react'
import talkMeDataProvider from './dataProvider'
import { Admin, Resource, Layout, AppBar } from 'react-admin'
import { Route } from 'react-router-dom';


import BachataUserMenu from './components/BachataUserMenu';
import BachataMainMenu from './components/BachataMainMenu';
import merge from 'lodash/merge';
import authProvider from './authProvider';
import BachataLoginPage from './LoginPage';

import { createMuiTheme } from '@material-ui/core/styles';
import indigo from '@material-ui/core/colors/indigo';
import pink from '@material-ui/core/colors/pink';
import red from '@material-ui/core/colors/red';


import { ListUsers, CreateUser, EditUser } from './resources/Users';
import { CreateCompany, ListCompanies, ShowCompany } from './resources/Companies';
import { ListCallTouchWebHooks, CreateCallTouchWebHook, EditCallTouchWebHook } from './resources/CallTouchWebHook';
import { CreateBrightBoxIntegration, EditBrightBoxIntegration, ListBrightBoxIntegration } from './resources/BrightBoxIntegrations';
import { EditCallKeeper, CreateCallKeeper, ListCallKeeper } from './resources/CallKeeperWebHook';
import { ListHarabaWebHooks, CreateHarabaWebHook } from './resources/HarabaWebHooks';
import InstructionScreen from './screen/InstructionScreen';
import callKeeperTutorial from './screen/callKeeperTutorial';
import whatsappTutorial from './screen/whatsappTutorial';
import brightBoxTutorial from './screen/brightBoxTutorial';
import callTouchTutorial from './screen/callTouchTutorial';
import PeopleIcon from '@material-ui/icons/People';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import { ListCoMagic, EditCoMagic, CreateCoMagic } from './resources/CoMagicWebHook';
import { CreateWhatsappDataCollection, ListWhatsappDataCollection, ShowWhatsappDataCollection, EditWhatsappDataCollection } from './resources/whatsapp/WhatsappDataCollection';


import polyglotI18nProvider from 'ra-i18n-polyglot';
import russianMessages from 'ra-language-russian';
import coMagicTutorial from './screen/coMagicTutorial';
import { CreateBrightBoxIntegrationV2, EditBrightBoxIntegrationV2, ListBrightBoxIntegrationV2 } from './resources/BrightBoxIntegrationsV2';
import brightBoxTutorial2 from './screen/brightBoxTutorial2';
import { CreateBmwIntegration, ListBmwIntegration } from './resources/BmwItegration';
import { EditBmwIntegration } from './resources/BmwItegration/EditBmwIntegration';
import BmwTutorial from './screen/bmwTutorial';
import GuruTutorial from './screen/guruTutorial';
import { CreateGuruIntegration, ListGuruIntegration, EditGuruIntegration} from './resources/GuruIntegration';

const i18nProvider = polyglotI18nProvider(() => russianMessages, 'ru');

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: '#ffffff'
    },
  },
  status: {
    danger: 'orange',
  },
});

const bachataTheme = merge({}, theme, {
  palette: {
    primary: indigo,
    secondary: pink,
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Arial', 'sans-serif'].join(','),
  },
  overrides: {
    MuiButton: {
      root: {
        color: 'gray',
      },
    },
    MuiFilledInput: {
      root: {
        background: null,
      }
    },
    MuiInputBase: {
      root: {
        background: null,
      }
    },
  },
});


const customPages = [
  <Route exact path="/instructions" component={InstructionScreen} />,
  <Route exact path="/brightBoxTutorial" component={brightBoxTutorial} />,
  <Route exact path="/brightBoxTutorial2" component={brightBoxTutorial2} />,
  <Route exact path="/calltouchTutorial" component={callTouchTutorial} />,
  <Route exact path="/callkeeperTutorial" component={callKeeperTutorial} />,
  <Route exact path="/whatsappTutorial" component={whatsappTutorial} />,
  <Route exact path="/comagicTutorial" component={coMagicTutorial} />,
  <Route exact path="/bmwTutorial" component={BmwTutorial} />,
  <Route exact path="/guruTutorial" component={GuruTutorial} />,
]

const BachataAppBar = props => <AppBar  {...props} elevation={2} userMenu={<BachataUserMenu />} />

const AppLayout = props => <Layout {...props} menu={BachataMainMenu} appBar={BachataAppBar} />

const adminResources = [
  <Resource
    name='users'
    list={ListUsers}
    create={CreateUser}
    edit={EditUser}
    icon={PeopleIcon}
    label='Пользователи'
    options={{ label: 'Пользователи' }}
  />,
  <Resource
    name='companies'
    list={ListCompanies}
    create={CreateCompany}
    show={ShowCompany}
    label='Компании'
    icon={BusinessCenterIcon}
    options={{ label: 'Компании' }}
  />,
  <Resource
    name='haraba/webhooks'
    list={ListHarabaWebHooks}
    create={CreateHarabaWebHook}
    label='Haraba'
    icon={DirectionsCarIcon}
    options={{ label: 'Haraba' }}
  />
]

const companyResources = [
  <Resource
    name='calltouch/webhooks'
    list={ListCallTouchWebHooks}
    edit={EditCallTouchWebHook}
    create={CreateCallTouchWebHook}
    label='CallTouch'
    options={{ label: 'CallTouch' }}
  />,
  <Resource
    name='companies'
    list={ListCompanies}
    create={CreateCompany}
    show={ShowCompany}
    label='Компании'
    options={{ label: 'Компании' }}
  />,
  <Resource
    name='calltouch/webhooks'
    list={ListCallTouchWebHooks}
    edit={EditCallTouchWebHook}
    create={CreateCallTouchWebHook}
    label='CallTouch'
    options={{ label: 'CallTouch' }}
  />,
  <Resource
    name='bright-box/webhooks'
    list={ListBrightBoxIntegration}
    edit={EditBrightBoxIntegration}
    create={CreateBrightBoxIntegration}
    label='BrightBox'
    options={{ label: 'BrightBox' }}
  />,
  <Resource
    name='bright-box/v2/webhooks'
    list={ListBrightBoxIntegrationV2}
    edit={EditBrightBoxIntegrationV2}
    create={CreateBrightBoxIntegrationV2}
    label='BrightBox v2'
    options={{ label: 'BrightBox v2' }}
  />,
  <Resource
    name='callkeeper/webhooks'
    list={ListCallKeeper}
    edit={EditCallKeeper}
    create={CreateCallKeeper}
    label='CallKeeper'
    options={{ label: 'CallKeeper' }}
  />,
  <Resource
    name='co-magic/webhooks'
    list={ListCoMagic}
    edit={EditCoMagic}
    create={CreateCoMagic}
    label='CallKeeper'
    options={{ label: 'CallKeeper' }}
  />,
  <Resource
    name='wapp-data-collection'
    list={ListWhatsappDataCollection}
    create={CreateWhatsappDataCollection}
    show={ShowWhatsappDataCollection}
    edit={EditWhatsappDataCollection}
    label='WhatsApp'
    options={{ label: 'WhatsApp' }}
  />,
  <Resource
    name='bmw/webhooks'
    create={CreateBmwIntegration}
    list={ListBmwIntegration}
    edit={EditBmwIntegration}
    label='BMW'
    options={{ label: 'BMW' }}
  />,
  <Resource
    name='guru/webhooks'
    create={CreateGuruIntegration}
    list={ListGuruIntegration}
    edit={EditGuruIntegration}
    label='Guru'
    options={{ label: 'Guru' }}
  />,
]

const App = () => {
  return (
    <Admin
      dataProvider={talkMeDataProvider(`${process.env.REACT_APP_ENTRYPOINT}/api`)}
      loginPage={BachataLoginPage}
      authProvider={authProvider}
      theme={bachataTheme}
      appLayout={AppLayout}
      customRoutes={customPages}
      i18nProvider={i18nProvider}
    >
      {
        permissions => [
          +permissions >= 100 ? adminResources : null,
          ...companyResources,
        ]
      }


    </Admin>
  )
}

export default App;
