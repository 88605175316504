import React, { useState } from 'react'

import {
    Edit,
    SimpleForm,
    ReferenceInput,
    AutocompleteInput,
    FunctionField,
    ArrayInput,
    SimpleFormIterator,
    TextInput,
    useNotify,
    required,
    SelectInput,
    FormDataConsumer,
    BooleanInput
} from 'react-admin'

import Axios from 'axios'


import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { useMediaQuery } from '@material-ui/core';

import { CopyButton } from '../../components/CopyButton';
import { timeZones } from './utils';
const copy = require('clipboard-copy')


const WidgetAside = ({ record, ...props }) => {
    const notify = useNotify()
    const widgetText = `
        <script>
        function sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        };
        
        async function connectToBachata() {
            while (true) {
                if (typeof Comagic == 'object' && typeof Comagic.getCredentials == 'function' && typeof OnlineChat == 'function') {
                    break;
                };
                await sleep(500);
            };
            __cs.push(["waitForTrackViewAndRun", function () {
                var creds = Comagic.getCredentials();
                OnlineChat("setClientInfo", {
                    custom: {
                        coMagicId: creds.comagic_id,
                        coMagicSessionId: creds.session_id,
                        coMagicVisitorId: creds.visitor_id
                    }
                });
            }]);
        };
        
        connectToBachata();
    </script>
    `
    return (
        <Paper>
            <div style={{ width: 500, margin: '1em', cursor: 'pointer' }}>
                <Typography variant="h6">Виджет для сайта</Typography>

                <pre style={{ whiteSpace: "normal" }} onClick={() => { copy(widgetText); notify('Скрипт скопирован') }}>
                    {widgetText}
                </pre>
            </div>
        </Paper>
    );
}


export const EditCoMagic = ({ permissions, ...props }) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'))

    const [isFetchADSData, setIsFetchADSData] = useState(false)
    const [adsCompanies, setAdsCompanies] = useState([])
    const [adsCompaniesError, setAdsCompaniesError] = useState(null)

    const getCoMagicAdsCompanies = async accessToken => {
        if (typeof accessToken == 'undefined' || accessToken === '' || accessToken.length != 40) {
            return []
        }

        if (!isFetchADSData) {
            setIsFetchADSData(true)
            Axios.get(`${process.env.REACT_APP_ENTRYPOINT}/hook/co-magic/ads-companies/${accessToken}`, {
            }).then(resp => {
                setAdsCompanies(resp.data)
                setAdsCompaniesError(null)
            }).catch(e => {
                setAdsCompaniesError(e.toString())
            })
        }
    }

    const bacahtaSources = [
        {
            id: 'instagram',
            name: 'Instagram',
            color: '#df2e76'
        },
        {
            id: 'whatsapp',
            name: 'WhatsApp',
            color: '#2abad2'
        },
        {
            id: 'whatsappOfficial',
            name: 'WhatsApp Official',
            color: '#25d466'
        },
        {
            id: 'telegram',
            name: 'Telegram',
            color: '#179cde'
        },
        {
            id: 'viber',
            name: 'Viber',
            color: '#665cac'
        },
        {
            id: 'fbUser',
            name: 'Facebook + Instagram (fbUser)',
            color: '#784eff'
        },
        {
            id: 'fbGroup',
            name: 'Facebook + Instagram (fbGroup)',
            color: '#784eff'
        },
        {
            id: 'vkUser',
            name: 'VK (vkUser)',
            color: '#507299'
        },
        {
            id: 'vkGroup',
            name: 'VK (vkGroup)',
            color: '#507299'
        },
        {
            id: 'okGroup',
            name: 'OK (okGroup)',
            color: '#ee8209'
        },
        {
            id: 'email',
            name: 'Email',
            color: '#FFC107'
        },
        {
            id: 'avito',
            name: 'Avito',
            color: '#009cf0'
        },
        {
            id: 'youla',
            name: 'Юла',
            color: '#8974fc'
        },
        {
            id: 'drom',
            name: 'Дром',
            color: '#DB001A'
        },
        {
            id: 'yandexDialogs',
            name: 'Яндекс.Диалоги',
            color: '#facf08'
        },
        {
            id: 'autoru',
            name: 'Auto.ru',
            color: '#db3727'
        },
    ]

    return (
        <Edit {...props} aside={!isSmall ? <WidgetAside  {...props} /> : null} title="Редактирование интеграции с CoMagic">
            <SimpleForm>
                <FunctionField render={record => (
                    <CopyButton text={`${process.env.REACT_APP_ENTRYPOINT}/hook/co-magic/${record.url_hash}`} />
                )} label="URL" sortable={false} />

                <FunctionField render={record => (
                    <CopyButton text={record.secret_key} />
                )} label="Секретный ключ" sortable={false} />


                <AutocompleteInput
                    label="Часовой пояс coMagic"
                    helperText={'Должен совпадать с Часовым поясом Аккаунта coMagic'}
                    source="co_magic_time_zone"
                    choices={timeZones}
                    optionText={'id'}
                    validate={required()}
                />

                <BooleanInput label="Отправлять Заявки" source="is_order_enabled" defaultChecked helperText={'Отправлять в coMagic Заявки'} />
                <BooleanInput label="Отправлять Чаты" source="is_chat_enabled" defaultChecked helperText={'Отправлять в coMagic Историю чата'}/>

                {
                    +permissions >= 100 && (
                        <ReferenceInput label="Компания" source="company_id" reference="companies" allowEmpty >
                            <AutocompleteInput helperText='Компания, к которой принадлежит пользователь. Может быть создана во вкладке "Компании"' emptyText="нет компании" optionText="name" />
                        </ReferenceInput>
                    )
                }



                <ArrayInput source="question_categories" label="Получать информацию только из этих категорий вопросов в Бачате">
                    <SimpleFormIterator>
                        <TextInput label="Имя категории " />
                    </SimpleFormIterator>
                </ArrayInput>

                {
                    adsCompaniesError ? (
                        <Typography h3 style={{ color: 'red' }}>
                            Ошибка получения информации от coMagic ({adsCompaniesError})
                        </Typography>
                    ) : null
                }


                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                        if (adsCompanies && adsCompanies.length === 0) {
                            getCoMagicAdsCompanies(formData.co_magic_access_token)
                            setIsFetchADSData(true)
                        }
                        const isDisabled = !formData.co_magic_access_token || formData.co_magic_access_token.length < 40
                        console.log(adsCompanies)
                        return (
                            <ArrayInput source="additional_ads_companies" label="Установка рекламных компаний для интеграций">
                                <SimpleFormIterator>
                                    <SelectInput source="integration" label="Интеграция" choices={bacahtaSources} validation={required} optionText={(record) => <span style={{ color: record.color }}>{record.name}</span>} />
                                    <SelectInput
                                        disabled={isDisabled}
                                        label="Рекламная компания"
                                        helperText={
                                            isDisabled ? 'Заполните поле Токен CoMagic'
                                                : 'Рекламная компания в CoMagic по умолчанию для выбранной интеграции Бачата'}
                                        source="ads_id"
                                        choices={adsCompanies}
                                        validate={required()}
                                    />
                                </SimpleFormIterator>
                            </ArrayInput>
                        )
                    }}
                </FormDataConsumer>

                {
                    isSmall ? <WidgetAside style={{ padding: 10 }} /> : null
                }
            </SimpleForm>
        </Edit>
    )
}
