import React from 'react'
import {
    List,
    Datagrid,
    CreateButton,
    TextField,
    FunctionField,
    TextInput,
    Filter,
    EditButton,
    ShowButton,
    SimpleList,
    ReferenceField
} from 'react-admin'

import { Box, Typography } from '@material-ui/core'
import { CopyButton } from '../../components/CopyButton'
import { useMediaQuery } from '@material-ui/core';


const Empty = ({ basePath }) => (
    <Box textAlign="center" m={1}>
        <Typography variant="h4" paragraph>
            У Вас нет интеграций с CallKeeper
        </Typography>
        <Typography variant="body1">
            Создайте их
        </Typography>
        <CreateButton size="big" basePath={basePath} />
    </Box>
)

export const ListCallKeeper = ({ permissions, ...props }) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (
        <List {...props} empty={<Empty />} filters={<ListFilter />} title="Интеграции с callkeeper.ru" exporter={false}>

            {
                isSmall ? (
                    <SimpleList
                        primaryText={record => (
                            <ReferenceField label="Компания" defaultValue="-" basePath="companies" record={record} source="company_id" reference="companies" link="edit" sortable={false}>
                                <TextField source="name" />
                            </ReferenceField>
                        )}
                        secondaryText={record => `#${record.id}`}
                        tertiaryText={record => new Date(record.created_at).toLocaleDateString()}
                    />
                ) : (
                        <Datagrid>
                            <TextField source="id" label="ID" />

                            <FunctionField
                                label="Компания"
                                render={
                                    r => {
                                        return r.company?.id ? ( 
                                            <a style={{textDecoration: 'none'}} href={`/#/companies/${r.company?.id}/show/3`}><div>{r.company?.name}</div></a>
                                        ):('-')
                                    }
                                }
                            />

                            <FunctionField render={record => (
                                <CopyButton text={`${process.env.REACT_APP_ENTRYPOINT}/hook/callkeeper/${record.url_hash}`} />
                            )} label="URL" sortable={false} />

                            <FunctionField render={record => (
                                <CopyButton text={record.secret_key} />
                            )} label="Секретный ключ" sortable={false} />

                            {
                                +permissions >= 100 && (
                                    <EditButton />
                                )
                            }

                            <ShowButton label={null} />
                        </Datagrid>
                    )
            }

        </List>
    )
}

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput source="companyName" label="Компания" />
    </Filter>
);