import React from 'react'

import {
  Edit,
} from 'react-admin'

import {
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
  TextInput,
  BooleanInput,
  FormDataConsumer,
  required
} from 'react-admin'

import { Card, CardContent, Grid, Typography } from '@material-ui/core'
import { handleFileUpload } from '../../components/handleUploadFile'

export const EditGuruIntegration = ({ permissions, record, ...props }) => {

  return (
    <Edit {...props} title="Редактирование интеграции с GURU" transform={handleFileUpload}>
      <SimpleForm redirect={'list'} >
        <Grid container spacing={2} style={{ width: '100%' }}>
          <Grid item xs={3}>

            <TextInput style={{ width: '100%' }} source="name" label="Название" validate={required()} />
            <TextInput style={{ width: '100%' }} source="bachata_rest_api_token" label="ID токена REST API Бачата" helperText={'ID токена, который вы получли на странице  Бачаты "Интеграция: REST API" '} validate={required()} />

            {
              +permissions >= 100 && (
                <>
                  <ReferenceInput style={{ width: '100%' }} label="Компания" source="company_id" reference="companies" allowEmpty >
                    <AutocompleteInput helperText='Компания, которой принадлежит интеграция. Может быть создана во вкладке "Компании"' emptyText="нет компании" optionText="name" />
                  </ReferenceInput>
                </>
              )
            }

            <br />

          </Grid>
          <Grid item xs={5}>
            <Card sx={{ minWidth: 275 }}>
              <CardContent>
                <Typography sx={{ fontSize: 14 }} h3 color="text.secondary" gutterBottom>
                  Запуск и остановка Бота
                </Typography>
                <br />
                <Typography variant="body2">
                  <TextInput style={{ width: '100%' }} source="guru_start_command" label="Команда старта" helperText={'После вывода этого сообщения Бот начнет работу. Будет отправлено приветственное сообщение и сообщение с просьбой ввести VIN номер'} validate={required()} />
                  <TextInput style={{ width: '100%' }} source="guru_end_command" label="Команда остановки" helperText={'После вывода этого сообщения Бот закончит работу. пользователь вернется в обычный режим чата'} validate={required()} />
                </Typography>
                <Typography sx={{ fontSize: 14 }} h3 color="text.secondary" gutterBottom>
                  Коммуникация с пользователем
                </Typography>
                <Typography variant="body2">
                  <TextInput multiline style={{ width: '100%' }} source="guru_begin_message" label="Приветственное сообщение" helperText={'Сообщение, которое будет показано пользователю в чате после выполнения команды запуска'} validate={required()} />
                  <TextInput multiline style={{ width: '100%' }} source="guru_end_message" label="Сообщение после получения результата" helperText={'После вывода этого сообщения оно  будет показано пользователю после получение ответа по диагностике'} validate={required()} />
                  <TextInput multiline style={{ width: '100%' }} source="guru_enter_vin_message" label="Сообщение с просьбой ввести VIN номер" helperText={'После вывода этого сообщения пользователю будет предложено ввести VIN номер'} validate={required()} />
                </Typography>
              </CardContent>
            </Card>

          </Grid>
          <Grid item xs={4}>
            <FormDataConsumer>
              {({ formData, ...rest }) => formData?.id ? (
                <Card sx={{ minWidth: 275 }}>
                  <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="text.primary" gutterBottom>
                      Последняя ошибка
                      {
                        (formData?.id && formData.last_error_date) ? ` (${new Date(formData.last_error_date).toLocaleDateString()})` : null
                      }

                    </Typography>
                    <br />
                    <Typography sx={{ fontSize: 12, color: "#c4" }} gutterBottom>
                      <code style={{ fontSize: 12, color: "#c4", overflowWrap: 'break-word' }}>{(formData?.id && formData.last_error_date) ? formData.last_error_text : 'Нет ошибок'}</code>
                    </Typography>
                    <br />
                  </CardContent>
                </Card>
              ) : null}
            </FormDataConsumer>
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  )
}


