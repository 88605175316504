import React, { useState } from 'react';

import 'github-markdown-css'


import { Title } from 'react-admin'

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';



import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button';
import { useMediaQuery } from '@material-ui/core';
import useWindowSize from '../hooks/useWindowSize';


const instructions = [
    {
        link: '/#/calltouchTutorial',
        name: 'CallTouch',
        img: require('../assets/img/logo/calltouchLogo.svg').default,
    },
    {
        link: '/#/brightBoxTutorial',
        name: 'Bright Box (email)',
        img: require('../assets/img/logo/brightLogoEmail.png').default,
    },
    {
        link: '/#/brightBoxTutorial2',
        name: 'Bright Box',
        img: require('../assets/img/logo/brightLogo.png').default,
    },
    {
        link: '/#/callkeeperTutorial',
        name: 'CallKeeper',
        img: require('../assets/img/logo/callKeeperLogo.png').default,
    },
    {
        link: '/#/whatsappTutorial',
        name: 'WhatsApp',
        img: require('../assets/img/logo/whatsappSmallIcon.png').default,
    },
    {
        link: '/#/comagicTutorial',
        name: 'CoMagic',
        img: require('../assets/img/logo/coMagicLogo.png').default,
    },
    {
        link: '/#/bmwTutorial',
        name: 'BMW',
        img: require('../assets/img/logo/bmwLogo.png').default,
    },
    {
        link: '/#/guruTutorial',
        name: 'GURU',
        img: require('../assets/img/logo/guruLogo.png').default,
    },
]

export default () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const windowSize = useWindowSize()

    const cardStyle = {
        minWidth: isSmall ? windowSize.width / 2 : 400,
        maxWidth: isSmall ? windowSize.width / 2 : 400,
        display: 'flex',
        margin: isSmall ? 10 : 20,
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
    }

    const cardStyleArea = {
        minWidth: isSmall ? windowSize.width / 2 : 400,
        display: 'flex',
        margin: isSmall ? 5 : 10,
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        padding: isSmall ? 2 : 10
    }

    const cardImageStyle = {
        maxWidth: isSmall ? windowSize.width : 400,
        margin: 4,
    }



    return (
        <>
            <Title title="Инструкции для интеграций" />
            <div
                style={{
                    display: isSmall ? 'block' : 'grid',
                    gridTemplateColumns: '400px 400px 400px',
                    gridColumnGap: '1em',
                    gridRowGap: '1em',
                    margin: isSmall ? 2 : 10,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {
                    !isSmall ? (
                        instructions.map(instruction => (
                            <Card
                                key={instruction.link}
                                style={{ ...cardStyle }}
                            >
                                <CardActionArea
                                    style={cardStyleArea}
                                    onClick={() => window.open(instruction.link)}
                                >
                                    <img alt="" src={instruction.img} style={cardImageStyle} />
                                </CardActionArea>
                            </Card>
                        ))
                    ) : (
                        instructions.map(instruction => (
                            <Card
                                key={instruction.link}
                                style={{ marginTop: 10, padding: 2, paddingTop: 5, paddingBottom: 5 }}
                            >
                                <CardActionArea
                                    onClick={() => window.open(instruction.link, '_self')}
                                >
                                    <Typography color="textSecondary" gutterBottom>
                                        {instruction.name}
                                    </Typography>

                                </CardActionArea>
                            </Card>
                        ))
                    )
                }

                <NotifyUpdate />
            </div>
        </>
    )
}


const NotifyUpdate = props => {
    const [isOpen, setIsOpen] = useState(!localStorage.getItem('update-3.3'))

    const handleClose = () => {
        setIsOpen(false)
    }

    const handleCloseForAllTime = () => {
        localStorage.setItem('update-3.3', false)
        setIsOpen(false)
    }

    return (
        <>
            <Dialog
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Необходимо обновление интеграции с Calltouch</DialogTitle>
                <DialogContent>
                    <Box textAlign="center" m={1}>
                        <Typography variant="h4" paragraph>
                            Обновлена логика работы calltouch
                        </Typography>
                        <Typography variant="body1">
                            Для обеспеения нормальной работы истеграции, вам необходимо изменить существующие Web-Hook в интеграциях,
                            Настроить их по скриншоту ниже:
                            <br />
                            <img style={{ width: 550 }} src="https://i.imgur.com/LKLeZFm.png" />
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        Закрыть
                    </Button>
                    <Button onClick={handleCloseForAllTime} color="primary" autoFocus>
                        Закрыть и не показывать
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}


