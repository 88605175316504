import { stringify } from 'qs';
import merge from 'deepmerge';
import axios from 'axios';
import {
  GET_LIST,
  GET_ONE,
  CREATE,
  UPDATE,
  DELETE,
  GET_MANY,
  DELETE_MANY,
  GET_MANY_REFERENCE,
} from './actions';

import defaultSettings from './default-settings';
import { NotImplementedError } from './errors';
import init from './initializer';

// Set HTTP interceptors.
init();

/**
 * Maps react-admin queries to a JSONAPI REST API
 *
 * @param {string} apiUrl the base URL for the JSONAPI
 * @param {Object} userSettings Settings to configure this client.
 *
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for a data response
 */
export default (apiUrl, userSettings = {}) => (type, resource, params) => {
  let url = '';
  const settings = merge(defaultSettings, userSettings);

  const options = {
    headers: settings.headers,
  };

  switch (type) {
    case GET_LIST: {
      const { page, perPage } = params.pagination;

      // Create query with pagination params.
      const query = {
        'page': page,
        'perPage': perPage,
      };

      // Add all filter params to query.
      Object.keys(params.filter || {}).forEach((key) => {
        query[`${key}`] = params.filter[key];
      });

      // Add sort parameter
      if (params.sort && params.sort.field) {
        const prefix = params.sort.order === 'ASC' ? '' : '-';
        query.sort = `${prefix}${params.sort.field}`;
      }

      url = `${apiUrl}/${resource}?${stringify(query)}`;
      break;
    }

    case GET_ONE:
      url = `${apiUrl}/${resource}/${params.id}`;
      break;

    case GET_MANY:
      url = `${apiUrl}/${resource}`
      break

    case CREATE:
      url = `${apiUrl}/${resource}`;
      options.method = 'POST';
      console.log(1)
      options.data = JSON.stringify(params.data);
      console.log(9, options.data)
      break;

    case UPDATE:
      url = `${apiUrl}/${resource}/${params.id}`;
      options.method = settings.updateMethod;
      options.data = JSON.stringify(params.data);
      break;

    case DELETE:
      url = `${apiUrl}/${resource}/${params.id}`;
      options.method = 'DELETE';
      break;


    case GET_MANY_REFERENCE: {
      const query = {}
      // Add all filter params to query.
      console.log('params', params)
      Object.keys(params.filter || {}).forEach((key) => {
        query[key] = params.filter[key];
      });
      // Add the reference id to the filter params.
      query[params.target] = params.id;
      url = `${apiUrl}/${resource}?${stringify(query)}`;
      break;
    }

    case DELETE_MANY: {
      return Promise.all(
        params.ids.map(id => {
          url = `${apiUrl}/${resource}/${id}`;
          options.method = 'DELETE';
          axios.delete(url, settings)
        }),
      ).then(() => ({ data: [] }));
    }

    default:
      throw new NotImplementedError(`Unsupported Data Provider request type ${type}`);
  }

  return axios({ url, ...options })
    .then((response) => {

      let total;

      // For all collection requests get the total count.
      if ([GET_LIST, GET_MANY, GET_MANY_REFERENCE].includes(type)) {
        // When meta data and the 'total' setting is provided try
        // to get the total count.
        if (response.data && settings.total) {
          total = response.data.total_record;
        }

        // Use the length of the data array as a fallback.
        total = total || response.data.records.length;
      }

      switch (type) {
        case GET_MANY:
        case GET_LIST: {
          return ({
            data: response.data.records.map(value => Object.assign(
              { id: value.id },
              value,
            )),
            total,
          });

        }

        case GET_MANY_REFERENCE: {
          console.log('GET REFERENCE')
          console.log(response.data)
          return {
            data: response.data.records.map(value => Object.assign(
              { id: value.id },
              value,
            )),
            total,
          };
        }

        case GET_ONE: {
          console.log('GET ONE', response.data)
          const attributes = response.data;

          return {
            data: {
              ...attributes,
            },
          };
        }

        case CREATE: {
          const { id, attributes } = response.data;
          return {
            data: {
              id, ...attributes,
            },
          };
        }

        case UPDATE: {
          const { id, attributes } = response.data;

          return {
            data: {
              id, ...attributes,
            },
          };
        }

        case DELETE: {
          return {
            data: { id: params.id },
          };
        }

        default:
          throw new NotImplementedError(`Unsupported Data Provider request type ${type}`);
      }
    });
};

export const GenerateChoices = (array, idIsInt = false) => {
  let choices = []
  for (let [k, v] of Object.entries(array)) {
    choices.push({ id: idIsInt ? +k : k, name: v })
  }

  return choices
}