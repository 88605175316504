import React from 'react'

import {
    Edit,
    SimpleForm,
    ReferenceInput,
    AutocompleteInput,
    FunctionField,
} from 'react-admin'
import { CopyButton } from '../../components/CopyButton'

export const EditBrightBoxIntegration = ({ permissions, ...props }) => (
    <Edit {...props} title="Редактирование интеграции с Bright Box">
        <SimpleForm>
            <FunctionField render={record => (
                <CopyButton text={`${process.env.REACT_APP_ENTRYPOINT}/hook/bright-box/${record.url_hash}`} />
            )} label="URL" sortable={false} />

            <FunctionField render={record => (
                <CopyButton text={record.secret_key} />
            )} label="Секретный ключ" sortable={false} />

            {
                +permissions >= 100 && (
                    <ReferenceInput label="Компания" source="company_id" reference="companies" allowEmpty >
                        <AutocompleteInput helperText='Компания, к которой принадлежит пользователь Bright Box. Может быть создана во вкладке "Компании"' emptyText="нет компании" optionText="name" />
                    </ReferenceInput>
                )
            }

        </SimpleForm>
    </Edit>
)
