import React from 'react'
import {
    List,
    Datagrid,
    CreateButton,
    TextField,
    FunctionField,
    TextInput,
    Filter,
    EditButton,
    SimpleList,
    ReferenceField,
    ShowButton
} from 'react-admin'

import { Box, Typography } from '@material-ui/core'
import { CopyButton } from '../../components/CopyButton'
import { useMediaQuery } from '@material-ui/core';


const Empty = ({ basePath }) => (
    <Box textAlign="center" m={1}>
        <Typography variant="h4" paragraph>
            У Вас нет интеграций с CoMagic
        </Typography>
        <Typography variant="body1">
            Создайте их
        </Typography>
        <CreateButton size="big" basePath={basePath} />
    </Box>
)

export const ListCoMagic = ({ permissions, ...props }) => (
    <List {...props} empty={<Empty />} filters={<ListFilter />} title="Интеграции с comagic.ru" exporter={false}>
        <ListCoMagicTable {...props} />
    </List>
)

export const ListCoMagicTable = ({ permissions, ...props }) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'))


    return (
        <>
            {
                isSmall ? (
                    <SimpleList
                        primaryText={record => (
                            <ReferenceField label="Компания" basePath="companies" record={record} source="company_id" reference="companies" link="show" sortable={false}>
                                <TextField source="name" />
                            </ReferenceField>
                        )}
                        secondaryText={record => `#${record.id}`}
                        tertiaryText={record => new Date(record.created_at).toLocaleDateString()}
                    />
                ) : (
                        <Datagrid {...props}>
                            <TextField source="id" label="ID" />

                            <ReferenceField label="Компания" source="company_id" reference="companies" link="show" sortable={false}>
                                <TextField source="name" />
                            </ReferenceField>


                            <FunctionField render={record => (
                                <CopyButton text={`${process.env.REACT_APP_ENTRYPOINT}/hook/co-magic/${record.url_hash}`} />
                            )} label="URL" sortable={false} />

                            <FunctionField render={record => (
                                <CopyButton text={record.secret_key} />
                            )} label="Секретный ключ" sortable={false} />

                            {
                                +permissions >= 100 && (
                                    <EditButton label={null} />
                                )
                            }

                            <EditButton label={null} />

                        </Datagrid>
                    )
            }
        </>
    )
}

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput source="companyName" label="Компания" />
    </Filter>
);
