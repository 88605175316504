import React from 'react'

import {
    Edit,
    SimpleForm,
    ReferenceInput,
    AutocompleteInput,
    FunctionField,
    TextField,
    BooleanInput,
    ArrayInput,
    SimpleFormIterator,
    TextInput,
    required
} from 'react-admin'
import { CopyButton } from '../../components/CopyButton'
import { Typography } from '@material-ui/core';
import { DepartmentInput } from '../../components/DepartmentInput';
import { handleFileUpload } from '../../components/handleUploadFile';
import FileInput from '../../components/FileInput';

export const EditBrightBoxIntegration = ({ permissions, ...props }) => (
    <Edit {...props} undoable={false} title="Редактирование интеграции с Bright Box" transform={handleFileUpload}>
        <SimpleForm>
            <FunctionField render={record => (
                <CopyButton text={`${process.env.REACT_APP_ENTRYPOINT}/hook/v2/bright-box/${record.url_hash}`} />
            )} label="URL" sortable={false} />


            <FunctionField render={record => (
                <CopyButton text={record.secret_key} />
            )} label="Секретный ключ" sortable={false} />



            <FileInput
                source="bright_box_certificate_id"
                label="Сертификат авторизации BrightBox"
                helperText="заменить, еcли необходимо"
                validate={required()}
            />
            <TextInput source="bright_box_certificate_password" label="Пароль от сетификата BrightBox" validate={required()}/>



            <FunctionField render={record => (
                <a href={`${process.env.REACT_APP_ENTRYPOINT}/api/files/${record.dms_pfx.file_key}`}>Скачать</a>
            )} label="DMS Cертификат" sortable={false} />

            <TextField source="dms_certificate_password" label="Пароль от сертификата DMS" />


            <FunctionField render={record => (
                <CopyButton text={`${process.env.REACT_APP_ENTRYPOINT}:${process.env.REACT_APP_BRIGHT_BOX_ENDPOINT_PORT}`} />
            )} label="DMS Endpoint" sortable={false} />



            <Typography variant="subtitle1" >Передавать в Бачату информацию</Typography>

            <BooleanInput label="Заявки на ремонт" source="is_repair_enabled" defaultValue={true} />
            <DepartmentInput label="Отдел" allowEmpty source="repair_department"  {...props} />

            <BooleanInput label="Заявки на тест-драйв" source="is_test_drive_enabled" defaultValue={true} />
            <DepartmentInput label="Отдел" allowEmpty source="test_drive_department"  {...props} />

            <BooleanInput label="Заявки на страхование" source="is_insurance_enabled" defaultValue={true} />
            <DepartmentInput label="Отдел" allowEmpty source="insurance_department"  {...props} />

            <BooleanInput label="Заявки на покупку запчастей или аксессуаров" source="is_parts_enabled" defaultValue={true} />
            <DepartmentInput label="Отдел" allowEmpty source="parts_department"  {...props} />

            <BooleanInput label="Заявки на кредит" source="is_loan_enabled" defaultValue={true} />
            <DepartmentInput label="Отдел" allowEmpty source="loan_department"  {...props} />

            <BooleanInput label="Заявки на приобретение коллекционных аксессуаров" source="is_life_style_enabled" defaultValue={true} />
            <DepartmentInput label="Отдел" allowEmpty source="life_style_department"  {...props} />

            <BooleanInput label="Чат с диллером" source="is_requests_enabled" defaultValue={true} />
            <DepartmentInput label="Отдел" allowEmpty source="requests_department"  {...props} />

            <Typography variant="subtitle1" >Типы ТО</Typography>

            <BooleanInput label="Заявки на обслуживание (ТО)" source="is_maintenance_enabled" defaultValue={true} />
            <DepartmentInput label="Отдел" allowEmpty source="maintenance_department"  {...props} />

            <ArrayInput source="service_types" label="">
                <SimpleFormIterator>
                    <TextInput source="i" label="ID в BrightBox" validate={required()} />
                    <TextInput source="n" label="Название" validate={required()} />
                </SimpleFormIterator>
            </ArrayInput>

            {
                +permissions >= 100 && (
                    <ReferenceInput label="Компания" source="company_id" reference="companies" allowEmpty >
                        <AutocompleteInput helperText='Компания, к которой принадлежит пользователь Bright Box. Может быть создана во вкладке "Компании"' emptyText="нет компании" optionText="name" />
                    </ReferenceInput>
                )
            }

        </SimpleForm>
    </Edit>
)
