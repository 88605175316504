import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    FunctionField,
    ReferenceField,
    EditButton,
    Filter,
    TextInput,
    SimpleList
} from 'react-admin'
import { useMediaQuery } from '@material-ui/core'


export const ListUsers = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return(
    <List {...props} filters={<ListFilter />} title="Пользователи" exporter={false}>
        {
            isSmall ? (
                <SimpleList
                    primaryText={record => `#${record.id} ${record.email}`}
                    secondaryText={record => (
                        <ReferenceField label="Компания" basePath="companies" record={record} source="company_id" reference="companies" link="show" sortable={false}>
                            <TextField source="name" />
                        </ReferenceField>
                    )}
                    tertiaryText={record => `${UserRoles[record.role] || ''} ${new Date(record.created_at).toLocaleDateString()}`}
                />
            ) : (
                    <Datagrid
                        rowClick="edit"
                    >
                        <TextField source="id" label="ID" sortable={false} />
                        <FunctionField render={r => UserRoles[r.role] || ''} label="Роль" sortable={false} />
                        <TextField source="email" label="Email" sortable={false} />
                        <ReferenceField label="Компания" source="company_id" reference="companies" sortable={false}>
                            <TextField source="name" />
                        </ReferenceField>
                        <EditButton />
                    </Datagrid>
                )
        }

    </List>
)}

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput source="companyName" label="Компания" />
    </Filter>
);

export const UserRoles = {
    999: 'root',
    100: 'Администратор',
    50: 'Компания'
}