import React, { useState } from 'react';
import { createElement } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { MenuItemLink, getResources, usePermissions } from 'react-admin';
import DefaultIcon from '@material-ui/icons/ViewList';
import SyncIcon from '@material-ui/icons/Sync';
import SubMenu from './menu/SubMenu';

import MenuBookIcon from '@material-ui/icons/MenuBook';
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import { ReactComponent as CallTouchLogo } from "../assets/img/logo/calltouchSmallIcon.svg";
import { ReactComponent as BrightBoxLogo } from "../assets/img/logo/brightBoxSmallIcon.svg";
import { ReactComponent as CoMagicLogo } from "../assets/img/logo/coMagicSmallLogo.svg";
import { ReactComponent as CallKeeperLogo } from "../assets/img/logo/callkeeperSmallIcon.svg";
import { ReactComponent as WhatsAppLogo } from "../assets/img/logo/whatsappSmallLogo.svg";
import { ReactComponent as BmwLogo } from "../assets/img/logo/bmwSmallLogo.svg";
import { ReactComponent as GuruLogo } from "../assets/img/logo/guruSmallLogo.svg";



const Menu = ({ onMenuClick }) => {
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    const resources = useSelector(getResources);
    const { permissions } = usePermissions();

    const [state, setState] = useState({
        menuIntegrations: false,
        whatsappIntegrations: false,
        brightBoxIntegrations: false
    })

    const handleToggle = (menu) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    }

    return (
        <div>
            {
                isXSmall ? (
                    <>
                        <MenuItemLink
                            to={'/'}
                            primaryText={'Бачата Интеграции'}
                            leftIcon={<img src={require('../assets/img/logo/bachataLogo.png').default} width={25} height={25} alt={''} />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                        />
                        <hr />
                    </>
                ) : null
            }

            <MenuItemLink
                to="/instructions"
                primaryText="Инструкции"
                leftIcon={<MenuBookIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />

            {resources.map(resource => {
                if ([
                    'autoru/users',
                    'bachata/webhooks',
                    'calltouch/webhooks',
                    'bright-box/webhooks',
                    'callkeeper/webhooks',
                    'wapp-data-collection',
                    'co-magic/webhooks',
                    'bright-box/v2/webhooks',
                    'bmw/webhooks',
                    'guru/webhooks',
                ].indexOf(resource.name) !== -1) {
                    return null
                }


                if (permissions && +permissions < 100 && ['users', 'haraba/webhooks'].indexOf(resource.name) !== -1) {
                    return null
                }

                return (
                    <MenuItemLink
                        key={resource.name}
                        to={`/${resource.name}`}
                        primaryText={
                            (resource.options && resource.options.label) ||
                            resource.name
                        }
                        leftIcon={
                            resource.icon ? <resource.icon /> : <DefaultIcon />
                        }
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                    />
                )
            })}
            <SubMenu
                handleToggle={() => handleToggle('menuIntegrations')}
                isOpen={state.menuIntegrations}
                sidebarIsOpen={open}
                name="Интеграции"
                icon={createElement(SyncIcon)}

            >
                <MenuItemLink
                    to={`/calltouch/webhooks`}
                    primaryText={'Calltouch'}
                    leftIcon={<CallTouchLogo width={25} height={25} />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />
                <MenuItemLink
                    to={`/callkeeper/webhooks`}
                    primaryText={'CallKeeper'}
                    leftIcon={<CallKeeperLogo width={25} height={25} />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />
                <MenuItemLink
                    to={`/co-magic/webhooks`}
                    primaryText={'CoMagic'}
                    leftIcon={<CoMagicLogo width={25} height={25} />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />
                <MenuItemLink
                    to={`/bmw/webhooks`}
                    primaryText={'BMW'}
                    leftIcon={<BmwLogo width={25} height={25} />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />
                <MenuItemLink
                    to={`/guru/webhooks`}
                    primaryText={'Guru'}
                    leftIcon={<GuruLogo width={25} height={25} />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />
                <div
                    style={open ? {
                        marginLeft: 14
                    } : {}}
                >
                    <SubMenu
                        handleToggle={() => handleToggle('whatsappIntegrations')}
                        isOpen={state.whatsappIntegrations}
                        sidebarIsOpen={open}
                        name="WhatsApp"
                        icon={createElement(WhatsAppLogo, { width: 30, height: 30 })}

                    >
                        <MenuItemLink
                            to={`/wapp-data-collection`}
                            primaryText={'Сбор данных'}
                            leftIcon={createElement(PersonPinCircleIcon)}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                        />
                    </SubMenu>
                </div>
                <div
                    style={open ? {
                        marginLeft: 14
                    } : {}}
                >
                    <SubMenu
                        handleToggle={() => handleToggle('brightBoxIntegrations')}
                        isOpen={state.brightBoxIntegrations}
                        sidebarIsOpen={open}
                        name="BrightBox"
                        icon={createElement(BrightBoxLogo, { width: 30, height: 30 })}

                    >
                        <MenuItemLink
                            to={`/bright-box/v2/webhooks`}
                            primaryText={'Bright Box'}
                            leftIcon={createElement(QuestionAnswerIcon)}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                        />
                        <MenuItemLink
                            to={`/bright-box/webhooks`}
                            primaryText={'Bright Box (email)'}
                            leftIcon={createElement(AlternateEmailIcon)}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                        />
                    </SubMenu>
                </div>
            </SubMenu>
        </div>
    );
};

export default Menu;