import React from 'react'

import {
    FileInput as FileInputBase,
    FileField
} from 'react-admin'

const FileInput = (props) => {
    return (
        <FileInputBase
            {...props}
        >
            <FileField source="src" title="title" />
        </FileInputBase>
    )
}

export default FileInput