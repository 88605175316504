import Axios from 'axios'
import React, { useState, useEffect } from 'react'
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import {
    FunctionField,
    Show,
    SimpleShowLayout,
    TextField,
    useNotify
} from 'react-admin'
import { CopyButton } from '../../../components/CopyButton';
const copy = require('clipboard-copy')


const WidgetAside = ({ record, ...props }) => {
    console.log(props)
    const [widgetText, setWidgetText] = useState("Загрузка ...")
    const [buttonWidgetText, setButtonWidgetText] = useState("Загрузка ...")
    useEffect(() => {
        if (!record) {
            return
        }
        if (!record.widget_public_key) {
            return
        }
        setButtonWidgetText(`event.target.parentNode.href="https://wa.me/${record.wapp_phone}"; window.WappBachataDataCollect(event);window.open(event.target.parentNode.href);`)

        Axios.get(`${process.env.REACT_APP_ENTRYPOINT}/wup/w/get-client/${record.widget_public_key}`).then(resp => {
            setWidgetText(resp.data)
        })
    }, [record]);
    const notify = useNotify()
    return (
        <Paper>
            <div style={{ width: 500, margin: '1em', cursor: 'pointer' }}>
                <Typography variant="h5">Виджет для сайта</Typography>

                <pre style={{ whiteSpace: "normal", overflowWrap: "break-word" }} onClick={() => { copy(`<script>${widgetText}</script>`); notify('Скрипт скопирован') }}>
                    &lt;script&gt;
                        {widgetText}
                    &lt;/script&gt;
                </pre>

                <hr />
                <Typography variant="h5"> Скрипт для JavaSctipt кнопки </Typography>
                <Typography variant="h6"> Необходим, если выбран круглый стиль кнопки Бачата </Typography>
                <pre style={{ whiteSpace: "normal", overflowWrap: "break-word" }} onClick={() => { copy(buttonWidgetText); notify('Скрипт скопирован') }}>
                        {buttonWidgetText}
                </pre>
            </div>
        </Paper>
    );
}

export const ShowWhatsappDataCollection = props => {

    return (
        <Show aside={<WidgetAside  {...props} />} title="WhatsApp Сбор данных" {...props}>
            <SimpleShowLayout>
                <TextField style={{ width: 350 }} source="name" label="Название сайта" />
                <TextField style={{ width: 350 }} source="wapp_phone" label="Телефон WhatsApp" />
                <TextField style={{ width: 350 }} multiline source="welcome_text" label="Первое сообщение пользователя" />

                <FunctionField render={record => (
                    <CopyButton text={`${process.env.REACT_APP_ENTRYPOINT}/hook/whatsapp-data/${record.url_hash}`} />
                )} label="URL" sortable={false} />

                <FunctionField render={record => (
                    <CopyButton text={record.secret_key} />
                )} label="Секретный ключ" sortable={false} />

            </SimpleShowLayout>
        </Show>
    )
}
