import React from 'react'

import {
    Edit,
    SimpleForm,
    TextInput,
    SelectInput,
    ReferenceInput,
    AutocompleteInput,
    email,
    required,
    FormDataConsumer
} from 'react-admin'
import { GenerateChoices } from '../../dataProvider'
import { UserRoles } from './ListUsers'

export const EditUser = props => (
    <Edit title="Пользователи" {...props}>
        <SimpleForm validate={validateUserEdit}>
            <TextInput label='Email' source='email' validate={[required(), email()]} disabled />
            <TextInput label='Пароль' source='password' />
            <SelectInput
                label="Роль"
                source="role"
                validate={required()}
                choices={GenerateChoices(UserRoles, true)}
                disabled
            />

            <FormDataConsumer>
                {({ formData, ...rest }) => {
                    if (formData.role && formData.role >= 100) { // Компания
                        return (
                            <ReferenceInput {...rest} label="Компания" source="company_id" reference="companies" allowEmpty>
                                <AutocompleteInput helperText="Пользователь будет принадлежать выбранной компанией" emptyText="нет компании" optionText="name" />
                            </ReferenceInput>
                        )
                    }

                    return null
                }}
            </FormDataConsumer>

        </SimpleForm>
    </Edit>
)

const validateUserEdit = (values) => {
    const errors = {};
    if (values.password != undefined && (values.password.length < 8 || values.password.length > 80)) {
        errors.password = ['Неверная длинна пароля, мин 8'];
    }
    return errors
};
