import React from 'react'

import {
  Create,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin'
import { phoneValidator } from './ListWhatsappDataCollection'

export const CreateWhatsappDataCollection = props => {
  return (
    <Create {...props} title="WhatsApp Сбор данных">
      <SimpleForm>
        <TextInput style={{ width: 350 }} source="name" label="Название сайта" validate={required()} />
        <TextInput style={{ width: 350 }} source="rest_api_secret_key" label="ID токена REST API Бачата" helperText={'ID токена, который вы получли на странице  Бачаты "Интеграция: REST API" '} validate={required()} />
        <TextInput style={{ width: 350 }} source="wapp_phone" label="Телефон WhatsApp" helperText={'На него будут перенаправляться пользователи сайта '} validate={[phoneValidator(), required()]} />
        <TextInput
          style={{ width: 350 }}
          multiline
          source="welcome_text"
          label="Первое сообщение пользователя"
          helperText="В конце сообщения будет hash код для ндентефикации  пользователя с WhatsApp"
          initialValue="Отправьте, пожалуйста, это сообщение, чтобы начать диалог с менеджером"
          validate={required()}
        />
        <TextInput
          label="Рекламный номер на странице"
          helperText="Селектор элемента с рекламным номером (например: body > div.container-fluid.px-0.header-menu__top-box > span)"
          source="ads_phone_selector"
          style={{ width: 350 }}
        />
      </SimpleForm>
    </Create>
  )
}
