import React, { useState } from 'react'
import Axios from 'axios'

import {
  Create,
  SimpleForm,
  TextInput,
  required,
  ReferenceInput,
  AutocompleteInput,
  ArrayInput,
  SimpleFormIterator,
  FormDataConsumer,
  SelectInput,
  BooleanInput
} from 'react-admin'

export const CreateCoMagic = ({ permissions, ...props }) => {
  const [sites, setSites] = useState([])

  const getCoMagicSites = async accessToken => {
    if (typeof accessToken == 'undefined' || accessToken === '' || accessToken.length != 40) {
      return []
    }

    console.log(accessToken.length)

    Axios.get(`${process.env.REACT_APP_ENTRYPOINT}/hook/co-magic/sites/${accessToken}`, {
    }).then(resp => {
      setSites(resp.data)
    })
  }

  return (
    <Create {...props} title="Создание интеграции с comagic.ru">
      <SimpleForm redirect={'list'}>

        <TextInput source="co_magic_access_token" label="Токен CoMagic" helperText={'Вечный токен пользователя CoMagic с доступом в Data API'} validate={required()} />

        <BooleanInput label="Отправлять Заявки" source="is_order_enabled" defaultValue={true} helperText={'Отправлять в coMagic Заявки'} />
        <BooleanInput label="Отправлять Чаты" source="is_chat_enabled" defaultValue={true} helperText={'Отправлять в coMagic Историю чата'} />

        <FormDataConsumer>
          {({ formData, ...rest }) => {
            if (sites && sites.length == 0) {
              getCoMagicSites(formData.co_magic_access_token)
            }
            const isDisabled = !formData.co_magic_access_token || formData.co_magic_access_token.length < 40
            console.log(sites)
            return (
              <SelectInput
                disabled={isDisabled}
                label="Сайт по умолчанию"
                helperText={
                  isDisabled ? 'Заполните поле Токен CoMagic'
                    : 'Сайт в CoMagic по умолчанию, на который будут отпраляться заявки с источников, не связанных с сайтом'}
                source="co_magic_site_id"
                choices={sites}
                validate={required()}
              />
            )
          }}
        </FormDataConsumer>

        {
          +permissions >= 100 && (
            <ReferenceInput label="Компания" source="company_id" reference="companies" allowEmpty >
              <AutocompleteInput helperText='Компания, к которой принадлежит пользователь. Может быть создана во вкладке "Компании"' emptyText="нет компании" optionText="name" />
            </ReferenceInput>
          )
        }

        <ArrayInput source="question_categories" label="Получать информацию только из этих категорий вопросов">
          <SimpleFormIterator>
            <TextInput label="Имя категории " />
          </SimpleFormIterator>
        </ArrayInput>

      </SimpleForm>
    </Create>
  )
}


