import React, { useState, useEffect } from 'react';

import ReactMarkdown from 'react-markdown'

import 'github-markdown-css'
import doc from '../assets/brightBoxTutorial2.md'
import {Title} from 'react-admin'


export default () => {

    const [markdown, setMarkdown] = useState('# Загрузка ...')

    useEffect(() => {
        fetch(doc).then((response) => response.text()).then(text => {
            setMarkdown(text)
            return true
        })
    }, [])

    return (
        <>
            <Title title="Инструкция по интеграции Бачата с Bright Box" />
            <div className='markdown-body' style={{
                maxWidth: '72vw',
            }}>
                <h2><a href={require('../assets/brightBoxTutorial2.pdf').default} target="_blank">Скачать инструкцию</a></h2>
                <ReactMarkdown
                    source={markdown}
                    escapeHtml={true}
                    linkTarget="_blank"
                    renderers={{
                        heading: props => <h2 id={props.children[0].props.value}>{props.children}</h2>
                    }}
                />
            </div>
        </>
    )

};