import React from 'react'

import {
    Edit,
    SimpleForm,
    TextInput,
    required
} from 'react-admin'
import { phoneValidator } from './ListWhatsappDataCollection'

export const EditWhatsappDataCollection = ({ permissions, ...props }) => (
    <Edit {...props} title="Редактирование сбора данных WhatsApp">
        <SimpleForm>
            <TextInput style={{ width: 350 }} source="name" label="Название сайта" validate={required()} />
            <TextInput
                style={{ width: 350 }}
                source="wapp_phone"
                label="Телефон WhatsApp"
                helperText={'На него будут перенаправляться пользователи сайта. Формат 79889509745'}
                validate={[phoneValidator(), required()]}
            />
            <TextInput
                style={{ width: 350 }}
                multiline
                source="welcome_text"
                label="Первое сообщение пользователя"
                helperText="В конце сообщения будет hash код для ндентефикации  пользователя с WhatsApp"
                initialValue="Отправьте, пожалуйста, это сообщение, чтобы начать диалог с менеджером"
                validate={required()}
            />
            <TextInput
                label="Рекламный номер на странице"
                helperText="Селектор элемента с рекламным номером (например: body > div.container-fluid.px-0.header-menu__top-box > span)"
                source="ads_phone_selector"
                style={{ width: 350 }}
            />
        </SimpleForm>
    </Edit>
)
