import React from 'react'

import {
  Edit,
} from 'react-admin'

import {
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
  TextInput,
  BooleanInput,
  FormDataConsumer,
  required
} from 'react-admin'

import { Card, CardContent, Grid, Typography } from '@material-ui/core'
import { handleFileUpload } from '../../components/handleUploadFile'

export const EditBmwIntegration = ({ permissions, record, ...props }) => {

  return (
    <Edit {...props} title="Редактирование интеграции с BMW" transform={handleFileUpload}>
      <SimpleForm redirect={'list'} >
        <Grid container spacing={2} style={{ width: '1200px' }}>
          <Grid item xs={3}>

            <TextInput style={{ width: '100%' }} source="name" label="Название" validate={required()} />
            <TextInput style={{ width: '100%' }} source="bachata_api_token" label="ID токена REST API Бачата" helperText={'ID токена, который вы получли на странице  Бачаты "Интеграция: REST API" '} validate={required()} />

            {
              +permissions >= 100 && (
                <>
                  <ReferenceInput style={{ width: '100%' }} label="Компания" source="company_id" reference="companies" allowEmpty >
                    <AutocompleteInput helperText='Компания, которой принадлежит интеграция. Может быть создана во вкладке "Компании"' emptyText="нет компании" optionText="name" />
                  </ReferenceInput>
                </>
              )
            }

            <br />

          </Grid>
          <Grid item xs={4}>
            <Card>
              <CardContent>
                <Typography sx={{ fontSize: 14 }} h3 color="text.secondary" gutterBottom>
                  Формы CIST
                </Typography>
                <br />
                <Typography variant="body2">
                  <BooleanInput label="RFO - Покупка новых авто" source="is_cist_rfo_buy_drive_enabled" defaultValue={true} />
                  <FormDataConsumer>
                    {({ formData, ...rest }) => formData.is_cist_rfo_buy_drive_enabled ? (
                      <>
                        <TextInput disabled label="Имя поля дополнительной формы" source="cist_rfo_buy_drive_additional_form_field_name" defaultValue={'order_type'} helperText={"Настройки -> Дополнительные вкладки -> Настройки формы -> Собираемые данные -> Системное имя поля (для API)"} style={{ width: '100%' }} validate={required()} />
                        <TextInput label="Значение поля дополнительной формы" source="cist_rfo_buy_drive_additional_value_field_name" helperText={"Настройки -> Дополнительные вкладки -> Настройки формы -> Собираемые данные -> Значение поля "} style={{ width: '100%' }} validate={required()} />
                        <TextInput label="CIST WebFormID" source="cist_rfo_buy_drive_web_form_id" style={{ width: '100%' }} validate={required()} />
                        <TextInput label="CIST X-Token" source="cist_rfo_buy_drive_token" style={{ width: '100%' }} validate={required()} />
                      </>
                    ) : null}
                  </FormDataConsumer>
                  <BooleanInput label="TDR - Тест-драйв" source="is_cist_rfo_test_drive_enabled" defaultValue={true} />
                  <FormDataConsumer>
                    {({ formData, ...rest }) => formData.is_cist_rfo_test_drive_enabled ? (
                      <>
                        <TextInput disabled label="Имя поля дополнительной формы" source="cist_rfo_test_drive_additional_form_field_name" defaultValue={'order_type'} helperText={"Настройки -> Дополнительные вкладки -> Настройки формы -> Собираемые данные -> Системное имя поля (для API)"} style={{ width: '100%' }} validate={required()} />
                        <TextInput label="Значения поля дополнительной формы" source="cist_rfo_test_drive_additional_value_field_name" helperText={"Настройки -> Дополнительные вкладки -> Настройки формы -> Собираемые данные -> Значение поля "} style={{ width: '100%' }} validate={required()} />
                        <TextInput label="CIST WebFormID" source="cist_rfo_test_drive_web_form_id" style={{ width: '100%' }} validate={required()} />
                        <TextInput label="CIST X-Token" source="cist_rfo_test_drive_token" style={{ width: '100%' }} validate={required()} />
                      </>
                    ) : null}
                  </FormDataConsumer>
                </Typography>
              </CardContent>
            </Card>

          </Grid>
          <Grid item xs={5}>
            <FormDataConsumer>
              {({ formData, ...rest }) => formData?.id ? (
                <Card sx={{ minWidth: 275 }}>
                  <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="text.primary" gutterBottom>
                      Последняя ошибка
                      {
                        (formData?.id && formData.last_error_date) ? ` (${new Date(formData.last_error_date).toLocaleDateString()})` : null
                      }

                    </Typography>
                    <br />
                    <Typography sx={{ fontSize: 12, color: "#c4" }} gutterBottom>
                      <code style={{ fontSize: 12, color: "#c4", overflowWrap: 'break-word' }}>{(formData?.id && formData.last_error_date) ? formData.last_error_text : 'Нет ошибок'}</code>
                    </Typography>
                    <br />
                  </CardContent>
                </Card>
              ) : null}
            </FormDataConsumer>
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  )
}


