import React from 'react';
import {
    Show,
    TextField,
    Pagination,
    ReferenceManyField,
    Datagrid,
    FunctionField,
    TabbedShowLayout,
    Tab,
    TabbedShowLayoutTabs,
} from 'react-admin';
import { CopyButton } from '../../components/CopyButton';
import { ListCallTouchWebHooksTable } from '../CallTouchWebHook';
import { ListCoMagicTable } from '../CoMagicWebHook/List';


export const ShowCompany = props => (
    <Show title="Компания" {...props}>
        <TabbedShowLayout
            tabs={<TabbedShowLayoutTabs variant="scrollable" />}
            {...props}
            >
                <Tab label="Общая информация">
                    <TextField source="name" label="Название" />
                </Tab>

                <Tab label="CallTouch">
                    <ReferenceManyField
                        label="Интеграции c CallTouch"
                        reference="calltouch/webhooks"
                        target="companyIds">
                            <ListCallTouchWebHooksTable {...props}/>
                    </ReferenceManyField>
                </Tab>

                <Tab label="CoMagic">
                    <ReferenceManyField
                        label="Интеграции с CoMagic"
                        reference="co-magic/webhooks"
                        target="companyIds">
                            <ListCoMagicTable {...props}/>
                    </ReferenceManyField>
                </Tab>

                <Tab label="CallKeeper">
                    <ReferenceManyField
                        pagination={<Pagination />}
                        label="Интеграции с callkeeper.ru"
                        reference="callkeeper/webhooks"
                        target="companyIds">
                        <Datagrid>
                            <TextField source="id" label="ID" />

                            <FunctionField render={record => (
                                <CopyButton text={`${process.env.REACT_APP_ENTRYPOINT}/hook/callkeeper/${record.url_hash}`} />
                            )} label="URL" sortable={false} />

                            <FunctionField render={record => (
                                <CopyButton text={record.secret_key} />
                            )} label="Секретный ключ" sortable={false} />

                        </Datagrid>
                    </ReferenceManyField>
                </Tab>

                <Tab label="Bright Box">
                    <ReferenceManyField
                        pagination={<Pagination />}
                        label="Bright Box"
                        reference="bright-box/webhooks"
                        target="companyIds">
                        <Datagrid>
                            <TextField source="id" label="ID" />

                            <FunctionField render={record => (
                                <CopyButton text={`${process.env.REACT_APP_ENTRYPOINT}/hook/bright-box/${record.url_hash}`} />
                            )} label="URL" sortable={false} />

                            <FunctionField render={record => (
                                <CopyButton text={record.secret_key} />
                            )} label="Секретный ключ" sortable={false} />

                        </Datagrid>
                    </ReferenceManyField>
                </Tab>

                <Tab label="BMW">
                    <ReferenceManyField
                        pagination={<Pagination />}
                        label="BMW"
                        reference="bmw/webhooks"
                        target="companyIds">
                        <Datagrid>
                            <TextField source="id" label="ID" />

                            <FunctionField render={record => (
                                <CopyButton text={`${process.env.REACT_APP_ENTRYPOINT}/hook/bright-box/${record.url_hash}`} />
                            )} label="URL" sortable={false} />

                            <FunctionField render={record => (
                                <CopyButton text={record.secret_key} />
                            )} label="Секретный ключ" sortable={false} />

                        </Datagrid>
                    </ReferenceManyField>
                </Tab>

        </TabbedShowLayout>
    </Show>
)