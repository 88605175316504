import jwt_decode from 'jwt-decode'
import { HttpError, ErrorList } from './dataProvider/errors'

const authProvider = {
  login: ({ username, password }) => {
    const request = new Request(`${process.env.REACT_APP_ENTRYPOINT}/api/auth`, {
      method: 'POST',
      body: JSON.stringify({ email: username, password }),
    })
    return fetch(request)
      .then(response => {
        return response.json()
      })
      .then(data => {

        if (data.message || data.error) {
          throw new HttpError(ErrorList[data.message] || data.message || data.error || JSON.stringify(data), data.status || 400)
        }

        const decodedJWT = jwt_decode(data.token);

        localStorage.setItem('token', data.token)
        localStorage.setItem('role', decodedJWT.role)
        localStorage.setItem('email', decodedJWT.email)
        localStorage.setItem('user_id', decodedJWT.sub)
      })
  },
  logout: () => {

    localStorage.clear()

    return Promise.resolve()
  },
  checkError: error => {
    const status = error.status
    if (status === 401 || status === 403) {
      localStorage.removeItem('token')
      return Promise.reject(new HttpError(error.message || error.error || JSON.stringify(error), status));
    }
    return Promise.resolve()
  },
  checkAuth: () => {
    return localStorage.getItem('token') ? Promise.resolve() : Promise.reject()
  },
  getPermissions: () => {
    const role = localStorage.getItem('role')
    return role ? Promise.resolve(role) : Promise.reject()
  }
}

export default authProvider
