import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    CreateButton,
    ShowButton
} from 'react-admin';

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

const Empty = ({ basePath, resource }) => (
    <Box textAlign="center" m={1}>
        <Typography variant="h4" paragraph>
            У Вас нет компаний
        </Typography>
        <Typography variant="body1">
            Создайте их
        </Typography>
        <CreateButton size="big" basePath={basePath} />
    </Box>
)

export const ListCompanies = (props) => (
    <List {...props} empty={<Empty />} title="Компании" exporter={false}>
        <Datagrid
            rowClick="show"
        >
            <TextField source="id" label="ID" sortable={false} />
            <TextField source="name" label="Название" sortable={false} />
            <ShowButton label={null} />
        </Datagrid>
    </List>
)