import React from 'react'

import {
    Edit,
    SimpleForm,
    ReferenceInput,
    AutocompleteInput,
    FunctionField,
    ArrayInput,
    SimpleFormIterator,
    TextInput,
    BooleanInput,
    required,
    SelectInput,
    NumberInput
} from 'react-admin'
import { CopyButton } from '../../components/CopyButton'

import { Grid } from '@material-ui/core';

export const EditCallTouchWebHook = ({ permissions, ...props }) => {


    const adiitionalRewriteURMSources = [
        {
            id: 'instagram',
            name: 'Instagram',
            color: '#df2e76'
        },
        {
            id: 'whatsapp',
            name: 'WhatsApp',
            color: '#2abad2'
        },
        {
            id: 'whatsappOfficial',
            name: 'WhatsApp Official',
            color: '#25d466'
        },
        {
            id: 'telegram',
            name: 'Telegram',
            color: '#179cde'
        },
        {
            id: 'viber',
            name: 'Viber',
            color: '#665cac'
        },
        {
            id: 'fbUser',
            name: 'Facebook + Instagram',
            color: '#784eff'
        },
        {
            id: 'vkUser',
            name: 'VK (vkUser)',
            color: '#507299'
        },
        {
            id: 'vkGroup',
            name: 'VK (vkGroup)',
            color: '#507299'
        },
        {
            id: 'okGroup',
            name: 'OK (okGroup)',
            color: '#ee8209'
        },
        {
            id: 'email',
            name: 'Email',
            color: '#FFC107'
        },
        {
            id: 'avito',
            name: 'Avito',
            color: '#009cf0'
        },
        {
            id: 'youla',
            name: 'Юла',
            color: '#8974fc'
        },
        {
            id: 'yandexDialogs',
            name: 'Яндекс.Диалоги',
            color: '#facf08'
        },
        {
            id: 'autoru',
            name: 'Auto.ru',
            color: '#db3727'
        },
        {
            id: 'drom',
            name: 'Дром',
            color: '#db3727'
        },
    ]

    return (
        <Edit {...props} title="Редактирование интеграции с calltouch.ru">
            <SimpleForm>


                <Grid container spacing={2} style={{ width: '100%' }}>
                    <Grid item xs={3}>

                        <FunctionField render={record => (
                            <CopyButton text={`${process.env.REACT_APP_ENTRYPOINT}/hook/calltouch/${record.url_hash}`} />
                        )} label="URL" sortable={false} />

                        <FunctionField render={record => (
                            <CopyButton text={record.secret_key} />
                        )} label="Секретный ключ" sortable={false} />

                        <TextInput source="call_touch_access_token" label="CallTouch Токен" helperText={'Токен, который вы получли в CallTouch  Во вкладке "Интеграции" -> "API и Webhooks" -> "API"" (Если не указан, то интеграция будет работать в старом формате)'} />

                        <NumberInput source="call_touch_id" label="Calltouch ID" />

                        <TextInput source="bachata_api_token" label="Online-чат API Токен (Бачата)" helperText={'Токен, от API Бачаты, можно получить во вкладке "Интеграции" -> "Rest API" -> "Online-чат" -> "Создать токен"'} />

                        <BooleanInput
                            source="need_send_question_category_tag"
                            label="Отправлять категорию запроса"
                            helperText="Нужно ли отправлять в CallTouch тег с названием отдела из чата в Бачате"
                        />

                        <BooleanInput
                            source="need_send_operator_tag"
                            label="Отправлять Имена операторов"
                            helperText="Нужно ли отправлять в CallTouch теги имена операторов из чата в Бачате"
                        />

                        <BooleanInput
                            source="need_send_whatsapp_phone_in_subject"
                            label="Отпрвлять Телефон из WhatsApp в Subject "
                            helperText="Отправлять дополнительно номер телефона клиента в Subject в Calltouch при использовании WhatsApp"
                        />

                        <BooleanInput
                            source="send_host_only_in_subject"
                            label="Отпрвлять в subject только host источника"
                            helperText="Отправлять ТОЛЬКО host  в Subject в Calltouch (напр. site | https://mysite.ru)"
                        />


                        <BooleanInput
                            source="send_dynamic_custom_fields"
                            label="Динамическая отправка тегов"
                            helperText="При изменении полей чата в Бачате, изменять теги в calltouch автоматически"
                        />

                        <BooleanInput
                            source="send_bachata_client_id_in_subject"
                            label="Отправлять ID Клиента Бачаты в subject"
                        />

                        <BooleanInput
                            source="send_bachata_subject_with_site"
                            label="Отправлять в subject 'Бачата | source' вместо просто 'source'"
                        />

                        <TextInput source="subject_custom_data" label="Доп. информация в начале subject" helperText={'Если пусто, информация передаваться не будет"'} />


                        {
                            +permissions >= 100 && (
                                <ReferenceInput label="Компания" source="company_id" reference="companies" allowEmpty >
                                    <AutocompleteInput helperText='Компания, к которой принадлежит пользователь auto.ru. Может быть создана во вкладке "Компании"' emptyText="нет компании" optionText="name" />
                                </ReferenceInput>
                            )
                        }

                        <ArrayInput source="question_categories" label="Проверать только эти категории ">
                            <SimpleFormIterator>
                                <TextInput label="Имя категории " />
                            </SimpleFormIterator>
                        </ArrayInput>

                    </Grid>
                    <Grid item xs={3}>
                        Вы можете заменить отправляемые UTM от сервисов для своих целей
                        <ArrayInput source="additional_utm_rewrites" label="Дополнительные настройки UTM">
                            <SimpleFormIterator>
                                <SelectInput source="integration" label="Интеграция" choices={adiitionalRewriteURMSources} validation={required} optionText={(record) => <span style={{ color: record.color }}>{record.name}</span>} />
                                <TextInput source="campaign" label="utm_campaign" />
                                <TextInput source="medium" label="utm_medium" />
                                <TextInput source="source" label="utm_source" />
                                <TextInput source="term" label="utm_term" />
                                <TextInput source="content" label="utm_content" />
                                <TextInput source="referrer" label="utm_referrer" />
                            </SimpleFormIterator>
                        </ArrayInput>
                    </Grid>
                    <Grid item xs={6}> </Grid>
                </Grid>
            </SimpleForm>
        </Edit>
    )
}
