import React from 'react'

import {
    Edit,
    SimpleForm,
    ReferenceInput,
    AutocompleteInput,
    FunctionField,
    ArrayInput,
    SimpleFormIterator,
    TextInput
} from 'react-admin'
import { CopyButton } from '../../components/CopyButton'

export const EditCallKeeper = ({ permissions, ...props }) => (
    <Edit {...props} title="Редактирование интеграции с callkeeper.ru">
        <SimpleForm>

            <FunctionField render={record => (
                <CopyButton text={`${process.env.REACT_APP_ENTRYPOINT}/hook/callkeeper/${record.url_hash}`} />
            )} label="URL" sortable={false} />

            <FunctionField render={record => (
                <CopyButton text={record.secret_key} />
            )} label="Секретный ключ" sortable={false} />

            {   
            +permissions >= 100 && (
                <ReferenceInput label="Компания" source="company_id" reference="companies" allowEmpty >
                <AutocompleteInput helperText='Компания, к которой принадлежит пользователь. Может быть создана во вкладке "Компании"' emptyText="нет компании" optionText="name" />
                </ReferenceInput>
            )
            }

            <ArrayInput source="question_categories" label="Получать информацию только из этих категорий вопросов в Бачате">
                
            <SimpleFormIterator>
                <TextInput label="Имя категории " />
            </SimpleFormIterator>
            </ArrayInput>

        </SimpleForm>
    </Edit>
)
