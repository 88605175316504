export class NotImplementedError extends Error {
  constructor(message) {
    super(message);

    this.message = message;
    this.name = 'NotImplementedError';
  }
}

export class HttpError extends Error {
  constructor(message, status) {
    super(message);

    this.message = message;
    this.status = status;
    this.name = 'HttpError';
  }
}


export const ErrorList = {
  "Key: 'UserCreateInput.Email' Error:Field validation for 'Email' failed on the 'email' tag": 'Неверный email или пароль',
  'invalid email or password': 'Неверный email или пароль',
  'user already exist': 'пользователь уже существует',
  'company already exist': 'компания уже существует',
  'you already have a company': 'У Вас уже есть компания',
  "Key: 'UserCreateInput.Password' Error:Field validation for 'Password' failed on the 'min' tag": "Пароль слинком короткий (мин. 8 символов)",
  "Key: 'UserCreateInput.Password' Error:Field validation for 'Password' failed on the 'max' tag": 'Пароль слишком длинный (макс. 255 символов)',
  'invalid access_token': 'Неверный токен авторизации coMagic',
  "Key: 'BrightBoxIntegrationCreateInputV2.BrightBoxCertificateID' Error:Field validation for 'BrightBoxCertificateID' failed on the 'required' tag": "Сертификат обязателен",
  "invalid certificate or password": 'Неверный сертификат или пароль от него'
}