import React from 'react'

import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
  email,
  required,
  FormDataConsumer
} from 'react-admin'
import { GenerateChoices } from '../../dataProvider'
import { UserRoles } from './ListUsers'

export const CreateUser = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput label='Email' source='email' validate={[required(), email()]} />
      <TextInput label='Пароль' source='password' validate={required()} />
      <SelectInput
        label="Роль"
        source="role"
        validate={required()}
        choices={GenerateChoices(UserRoles, true)}
      />

      <FormDataConsumer>
        {({ formData, ...rest }) => {
          if (formData.role && formData.role === 50) { // Компания
            return (
              <ReferenceInput {...rest} label="Компания" source="company_id" reference="companies" allowEmpty>
                <AutocompleteInput helperText="Пользователь будет принадлежать выбранной компанией" emptyText="нет компании" optionText="name" />
              </ReferenceInput>
            )
          }

          return null
        }}
      </FormDataConsumer>


    </SimpleForm>
  </Create>
)
