import React from 'react'
import { Datagrid, DateField, List, TextField, CreateButton, ShowButton, SimpleList, EditButton } from "react-admin";

import { Box, Typography } from '@material-ui/core'
import { useMediaQuery } from '@material-ui/core';

const Empty = ({ basePath }) => (
    <Box textAlign="center" m={1}>
        <Typography variant="h4" paragraph>
            Вы не собираете даныые из WhatsApp
        </Typography>
        <Typography variant="body1">
            Давайте начнем
        </Typography>
        <CreateButton size="big" basePath={basePath} />
    </Box>
)

export const phoneValidator = (message='Неверный формат. Должен быть 7877859283') => value => {
    const regexp = /^([7]\d{10})$/
    return regexp.test(value)? undefined : message
}

export const ListWhatsappDataCollection = (props) => (
    <List {...props} empty={<Empty />} title="Сбор данных WhatsApp" exporter={false} >
        <ListWhatsappDataCollectionTable />
    </List>
)

export const ListWhatsappDataCollectionTable = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'))


    return isSmall ? (
        <SimpleList
            primaryText={record => record.name}
            secondaryText={record => `#${record.id}`}
            tertiaryText={record => new Date(record.created_at).toLocaleDateString()}
        />
    ) : (
            <Datagrid>
                <TextField source="id" sortable={false} />
                <TextField source="name" label="Название сайта" sortable={false} />
                <DateField source="created_at" label="Дата создания" sortable={false} />

                <ShowButton label={null} />
                <EditButton label={null} />
            </Datagrid>
        )

}