import React from 'react'

import { Tooltip } from '@material-ui/core'
import { useNotify } from 'react-admin';

const copy = require('clipboard-copy')

export const CopyButton = ({ text }) => {
    const notify = useNotify();


    return (
        <div
            style={{
                cursor: 'pointer'
            }}
            onClick={() => {copy(text); notify('текст скопирован', 'info');}}
        >
            <Tooltip title="Скопировать">
                <span>
                    {
                        text
                    }
                </span>
            </Tooltip>
        </div>
    )
}