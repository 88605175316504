import React from 'react';
import { UserMenu } from 'react-admin';
import SettingsIcon from '@material-ui/icons/Settings';
import { MenuItem, Typography, Card, CardContent } from '@material-ui/core';



export default props => {

    const email = localStorage.getItem('email');
    const userId = localStorage.getItem('user_id');

    return (
        <UserMenu {...props}>
            <Card onClick={() => { }} style={{ outline: 'none' }} variant="outlined">
                <CardContent>
                    <Typography displayBlock alignCenter h6> {`#${userId} ${email}`}  </Typography>
                </CardContent>
                <CardContent>
                    <Typography variant='subtitle2'> {process.env.REACT_APP_VERSION} </Typography>
                </CardContent>
            </Card>
            <MenuItem>
                <SettingsIcon />
                <a style={{
                    textDecoration: 'none',
                    color: 'gray',
                    marginLeft: '19px'
                }}
                    target="_blank"
                    href={`/#/users/${userId}`}
                >
                    Настройки профиля
                </a>
            </MenuItem>
        </UserMenu>
    )
}


