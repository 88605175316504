import React from 'react'

import {
  Create,
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
  TextInput,
  required,
  BooleanInput,
  ArrayInput,
  SimpleFormIterator,
} from 'react-admin'

import FileInput from '../../components/FileInput'
import { handleFileUpload } from '../../components/handleUploadFile'
import { Typography } from '@material-ui/core';

import { DepartmentInput } from '../../components/DepartmentInput'

export const CreateBrightBoxIntegration = ({ permissions, ...props }) => {

  return (
    <Create {...props} title="Создание интеграции с Bright Box" transform={handleFileUpload}>
      <SimpleForm redirect={'list'}>

        <TextInput source="rest_api_secret_key" label="ID токена REST API Бачата" helperText={'ID токена, который вы получли на странице  Бачаты "Интеграция: REST API" '} validate={required()} />
        <FileInput
          source="bright_box_certificate_id"
          label="Сертификат авторизации"
          validate={required()}
        />
        <TextInput source="bright_box_certificate_password" label="Пароль от сетификата" validate={required()} />


        <Typography variant="subtitle1" >Передавать в Бачату информацию</Typography>

        <BooleanInput label="Заявки на ремонт" source="is_repair_enabled" defaultValue={true} />
        <DepartmentInput label="Отдел" allowEmpty source="repair_department"  {...props} />

        <BooleanInput label="Заявки на тест-драйв" source="is_test_drive_enabled" defaultValue={true} />
        <DepartmentInput label="Отдел" allowEmpty source="test_drive_department"  {...props} />

        <BooleanInput label="Заявки на страхование" source="is_insurance_enabled" defaultValue={true} />
        <DepartmentInput label="Отдел" allowEmpty source="insurance_department"  {...props} />

        <BooleanInput label="Заявки на покупку запчастей или аксессуаров" source="is_parts_enabled" defaultValue={true} />
        <DepartmentInput label="Отдел" allowEmpty source="parts_department"  {...props} />

        <BooleanInput label="Заявки на кредит" source="is_loan_enabled" defaultValue={true} />
        <DepartmentInput label="Отдел" allowEmpty source="loan_department"  {...props} />

        <BooleanInput label="Заявки на приобретение коллекционных аксессуаров" source="is_life_style_enabled" defaultValue={true} />
        <DepartmentInput label="Отдел" allowEmpty source="life_style_department"  {...props} />

        <BooleanInput label="Чат с диллером" source="is_requests_enabled" defaultValue={true} />
        <DepartmentInput label="Отдел" allowEmpty source="requests_department"  {...props} />

        <BooleanInput label="Заявки на обслуживание (ТО)" source="is_maintenance_enabled" defaultValue={true} />
        <DepartmentInput label="Отдел" allowEmpty source="maintenance_department"  {...props} />

        <Typography variant="subtitle1" >Типы ТО</Typography>

        <ArrayInput source="service_types" label="">
          <SimpleFormIterator>
            <TextInput source="i" label="ID в BrightBox"  validate={required()}  />
            <TextInput source="n" label="Название" validate={required()}  />
          </SimpleFormIterator>
        </ArrayInput>

        {
          +permissions >= 100 && (
            <ReferenceInput label="Компания" source="company_id" reference="companies" allowEmpty >
              <AutocompleteInput helperText='Компания, к которой принадлежит пользователь  Bright Box. Может быть создана во вкладке "Компании"' emptyText="нет компании" optionText="name" />
            </ReferenceInput>
          )
        }

      </SimpleForm>
    </Create>
  )
}
