import React from 'react'

import {
  Create,
  SimpleForm,
  TextInput,
  required,
  ReferenceInput,
  AutocompleteInput,
  ArrayInput,
  SimpleFormIterator
} from 'react-admin'

export const CreateCallKeeper = ({ permissions, ...props }) => {

  return (
    <Create {...props} title="Создание интеграции с callkeeper.ru">
      <SimpleForm redirect={'list'}>
       
        <TextInput source="call_keeper_api_key" label="Код Ключа" helperText={'Код ключа, который вы получли в Callkeeper Во вкладке "Профиль" -> "Ключ API" '} validate={required()} />
        <TextInput source="call_keeper_widget_hash" label="Hash виджета" helperText={'Hash виджета из которого будут взяты необходимые параметры для звонка, можно получить на странице редактирования виджета в ЛК" '} validate={required()} />
        
        {
          +permissions >= 100 && (
            <ReferenceInput label="Компания" source="company_id" reference="companies" allowEmpty >
              <AutocompleteInput helperText='Компания, к которой принадлежит пользователь. Может быть создана во вкладке "Компании"' emptyText="нет компании" optionText="name" />
            </ReferenceInput>
          )
        }

        <ArrayInput source="question_categories" label="Получать информацию только из этих категорий вопросов">
          <SimpleFormIterator>
            <TextInput label="Имя категории " />
          </SimpleFormIterator>
        </ArrayInput>

      </SimpleForm>
    </Create>
  )
}


