import React from 'react'

import {
  Create,
  SimpleForm,
  TextInput,
  required
} from 'react-admin'

export const CreateCompany = props => (
    <Create title="Компании" {...props}>
      <SimpleForm>
        <TextInput label='Название'  helperText='Название вашей компании' source='name' validate={required()} />
      </SimpleForm>
    </Create>
)
