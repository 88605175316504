import axios from "axios";

export const handleFileUpload = async (data) => {
    for await (const [key, val] of Object.entries(data)) {
      if (val?.rawFile) {
        const fd = new FormData();
        fd.set('file', val.rawFile) 
        try {
          const res = await axios(`${process.env.REACT_APP_ENTRYPOINT}/api/files`, {
            method: 'POST',
            data: fd,
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          data[key] = res.data.id
        }
        catch(e) {
          console.error(e);
        }
      }
    }
    return data
}