import React, { useState } from 'react'

import {
  Create,
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
  TextInput,
  required,
} from 'react-admin'

export const CreateHarabaWebHook = props => {

  return (
    <Create {...props} title="Интеграции с auto.ru">
      <SimpleForm>
        <TextInput source="haraba_token" label="Токен Haraba" helperText={'токен, который вы получли Haraba'} validate={required()} />
      </SimpleForm>
    </Create>
  )
}


